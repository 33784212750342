import React from "react"
import { Link } from "gatsby"
import BudiUpdated from "../../components/bs/updated"

import Layout from "../../components/bs/layout"
//import Image from "../components/image"
import SEO from "../../components/bs/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"
import logoHeadline from "./../../images/moonamar-proizvodnja-u-skladu-s-prirodom.png"
import ourStoryImg from "./../../images/proizvodi-sa-tezistem-na-prirodnom-porijeklu-sastojaka.png"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import product1 from "./../../images/moonamar-hladno-cijedeno-ulje-corokota-crnog-kima.png"
import product2 from "./../../images/moonamar-hladno-cijedeno-bundevino-bucino-ulje.png"
import product3 from "./../../images/moonamar-konopljino-ulje-iz-organskog-bio-sjemena.png"
import product4 from "./../../images/moonamar-hladno-cijedeno-djevicansko-laneno-ulje.png"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="MOONAMAR - PROIZVODIMO U SKLADU S PRIRODOM"
          description="MOONAMAR - Ekskluzivna selekcija dodataka prehrani i kozmetike sa težištem na prirodnom porijeklu blagotvornih sastojaka."
        />

        <section
          id="_hero"
          className="hero is-fullheight"
          style={{ backgroundImage: `url(${MainImg})` }}
        >
          <div id="bgHero">
            <span className="shape1">
              <img src={shape1} alt="Shape 1" />
            </span>
            <span className="shape2">
              <img src={shape2} alt="Shape 2" />
            </span>
            <span className="shape3">
              <img src={shape3} alt="Shape 3" />
            </span>
            <span className="shape4">
              <img src={shape4} alt="Shape 4" />
            </span>
            <span className="shape5">
              <img src={shape5} alt="Shape 5" />
            </span>
            <span className="shape6">
              <img src={shape6} alt="Shape 6" />
            </span>
            <span className="shape7">
              <img src={shape7} alt="Shape 7" />
            </span>
            <span className="shape8">
              <img src={shape2} alt="Shape 8" />
            </span>
            <span className="shape9">
              <img src={shape6} alt="Shape 9" />
            </span>
            <span className="shape10">
              <img src={shape3} alt="Shape 10" />
            </span>
            <span className="shape11">
              <img src={shape4} alt="Shape 11" />
            </span>
            <span className="shape12">
              <img src={shape1} alt="Shape 12" />
            </span>
            <span className="shape13">
              <img src={shape8} alt="Shape 13" />
            </span>
            <span className="shape14">
              <img src={shape7} alt="Shape 14" />
            </span>
            <span className="shape15">
              <img src={shape3} alt="Shape 15" />
            </span>
          </div>
          <div className="hero-body">
            <div className="container">
              <figure className="image">
                <img src={logoHeadline} alt="Dodaci prehrani i kozmetika" />
              </figure>
              <div className="title">U SKLADU</div>
              <div className="subtitle">s prirodom</div>
              <div className="_inner">
                <BudiUpdated />
              </div>
            </div>
          </div>
        </section>

        <span className="_line" />

        <section id="our-story" className="section">
          <span className="_dec2">
            <img src={shape8} alt="Dec 2" />
          </span>
          <div className="container">
            <div className="columns">
              <div className="column is-3 _headline">
                <h2 className="title is-2">Ekskluzivni izbor proizvoda</h2>
                <p className="content">
                  MOONAMAR proizvodi nastaju kao plod posvećenosti prirodi i
                  brižnog rada na selekciji najboljih receptura sa blagotvornim
                  dejstvima.
                </p>
              </div>
              <div className="column">
                <figure className="image is-3by4">
                  <img
                    src={ourStoryImg}
                    alt="Proizvodi sa težištem na prirodnom porijeklu sastojaka"
                    title="Proizvodi sa težištem na prirodnom porijeklu sastojaka"
                  />
                </figure>
              </div>
              <div className="column is-3 content _headline">
                <h2 className="title is-2 is-spaced _gold">INSPIRACIJA</h2>
                <p className="subtitle is-4">
                  Pogledaj duboko u prirodu i onda ćeš sve bolje razumjeti. -
                  Albert Ajnštajn
                </p>
                <p className="content">
                  Svaki čovjek je jedinstven kako sa svojim izgledom tako i sa
                  hormonalnim sustavom, imunitetom, emocionalnim, karakternim i
                  biohemijskim karakteristikama.
                </p>
                <p>
                  Shodno tome svaki čovjek razvija različite sklonosti i reaguje
                  na sebi svojstven način na poticaje iz svoje okoline. Iz tog
                  razloga isto i pri odabiru odgovarajuće ishrane, kozmetike,
                  dodataka ishrani, dijetetskih proizvoda...
                </p>
                <Link
                  to="/bs/inspiracija"
                  title="INSPIRACIJA IZ MUDROSTI PRIRODE"
                  className="button is-text is-small btn2"
                >
                  PROČITAJ VIŠE
                </Link>
              </div>
            </div>
          </div>
          <span className="_dec3">
            <img src={shape6} alt="Dec 3" />
          </span>
        </section>

        <section id="products" className="section">
          <div className="container is-fullhd">
            <div className="columns">
              <div className="column is-3 _headline">
                <h2 className="title is-2 is-spaced">MOONAMAR PRIRODNA ULJA</h2>
                <p className="subtitle">
                  Ekskluzivna selekcija prirodnih ulja sa dokazanim blagotvornim
                  dejstvima.
                </p>
                <p className="content">
                  U cilju očuvanja mnogobrojnih blagotvornih supstanci i
                  specifične upotrebe kao dadataka ishrani, MOONAMAR ulja se
                  proizvode blagom metodom hladnog cijeđenja, i ne podvrgavaju
                  se nikakvim dodatnim preradama.
                </p>
                <Link
                  to="/bs/proizvodi"
                  title="PRIRODNI DODACI PREHRANI I KOZMETIKA"
                  className="button is-primary is-rounded is-small btn1head"
                >
                  SVI PROIZVODI
                </Link>
              </div>
              <div className="column is-9">
                <Carousel
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product1}
                      alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>1</span>
                        <div className="title">Čorokotovo ulje</div>
                        <div className="subtitle">
                          Inspirisano tradicijom velikih religija i kultura
                        </div>
                        <p className="content">
                          Slab imunitet čini tijelo podložno infekcijama a
                          neuravnotežen imunitet dovodi do alergijskih
                          oboljenja. Čorokot djeluje blagotvorno pri
                          balansiranju imuniteta kao temelja održavanja
                          zdravlja.
                        </p>
                        <p className="content">
                          Tradicionalno se najčešće koristi protiv alergija i
                          kao sredstvo za jačanje imuniteta.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price">22,90 KM</div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                              to="/bs/proizvod/corokotovo-ulje"
                              className="btnMore"
                            >
                              Više o proizvodu
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <a href="https://zdravo.ba/proizvod/zdravlje/moonamar/corokotovo-ulje-hladno-cijedjeno-nefiltrirano">
                          KUPI PROIZVOD
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product2}
                      alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE "
                      title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE "
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>2</span>
                        <div className="title">Bundevino (bučino) ulje</div>
                        <div className="subtitle">
                          Blagodat iz prirode za sredovječnu i stariju mušku
                          populaciju
                        </div>
                        <p className="content">
                          Mnogobrojna naučna istraživanja urađena na
                          sredovječnoj i starijoj muškoj populaciji ukazuju na
                          njegovo blagotvorno dejstvo na prostatu. Redovna
                          konzumacija pomaže organizmu kod problema povećane
                          prostate.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price">21,90 KM</div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE "
                              to="/bs/proizvod/bundevino-ulje"
                              className="btnMore"
                            >
                              Više o proizvodu
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <a href="https://zdravo.ba/proizvod/zdravlje/moonamar/moonamar-undevino-ulje-(-blagodat-za-prostatu-)">
                          KUPI PROIZVOD
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product3}
                      alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>3</span>
                        <div className="title">
                          Konopljino ulje iz BIO sjemena
                        </div>
                        <div className="subtitle">
                          Uvijek u centru zbivanja – Vaš saveznik u borbi protiv
                          metaboličkih poremećaja
                        </div>
                        <p className="content">
                          Konopljin fantastičan biohemijski sastav je potencijal
                          koji je izdiže iznad svih predrasuda i ponovo uvodi u
                          upotrebu kao izvanrednu potporu organizmu protiv
                          metaboličkih poremećaja.
                        </p>
                        <p className="content">
                          Pogodno je kako za oralnu upotrebu tako i za
                          utrljavanje u kožu.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price">22,90 KM</div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                              to="/bs/proizvod/konopljino-ulje"
                              className="btnMore"
                            >
                              Više o proizvodu
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <a href="https://zdravo.ba/proizvod/zdravlje/moonamar/konopljino-ulje-250-ml">
                          KUPI PROIZVOD
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product4}
                      alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>4</span>
                        <div className="title">Laneno ulje</div>
                        <div className="subtitle">
                          Omega-3-šampion biljnog porijekla
                        </div>
                        <p className="content">
                          Iznimno visoka koncentracija OMEGA-3 čini ga veoma
                          poželjnim dodatkom ishrani u prevenciji oboljenja
                          krvnih sudova, te samim tim i srčanog i moždanog
                          udara.
                        </p>
                        <p className="content">
                          Preporučljivo je kako za oralnu upotrebu tako i za
                          utrljavanje u kožu.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price">11,90 KM</div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                              to="/bs/proizvod/laneno-ulje"
                              className="btnMore"
                            >
                              Više o proizvodu
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <a href="https://zdravo.ba/proizvod/zdravlje/ostalo/laneno-ulje-djevicansko-hladno-cijedjeno">
                          KUPI PROIZVOD
                        </a>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
          <span className="_dec1">
            <img src={shape7} alt="Dec 1" />
          </span>
          <span className="_dec1a">
            <img src={shape3} alt="Dec 1" />
          </span>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
